import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Icon from '@cxeweb/pattern-library/lib/components/icon';
import iconPaths from '@cxeweb/pattern-library/lib/icons/__index';
import Overlay from '@cxeweb/pattern-library/lib/components/overlay';
import Button from '@cxeweb/pattern-library/lib/components/button';
import Heading from '@cxeweb/pattern-library/lib/components/heading';
import Logo from '@cxeweb/pattern-library/lib/components/logo';
import Images from '../../images';
import LanguageSwitcher from '../languageSwither/LanguageSwitcher';
import { useTranslation } from 'react-i18next';

import './Header.css';

const Header = () => {
    const { t } = useTranslation();
    const [isOverlayOpen, setIsOverlayOpen] = useState(false);

    const openOverlay = () => {
        setIsOverlayOpen(true);
    };

    const closeOverlay = () => {
        setIsOverlayOpen(false);
    };

    return (
        <header className='header'>
            <div className='header-logo'>
                <Logo aria-label='Home' href='/' size='60px' />
            </div>
            <div className='header-title'>
                <Link to='/visit'>
                    <img className='tm-top' src={Images.TM_top} alt='TM_top' />
                </Link>
            </div>
            <div className='header-menu'>
                <Icon path={iconPaths.hamburger} size='60px' onClick={openOverlay} />
            </div>
            {isOverlayOpen && (
                <Overlay
                    alignment='rightThird'
                    closeProps={{
                        ariaLabel: 'Close',
                        onClick: closeOverlay,
                    }}
                    style={{ zIndex: 999 }}
                >
                    <div className='sb-global-gutters'>
                        <Heading id='overlay1-heading' tagName='h2' size='md' className='text-semibold pb4'>
                            {t('translation.select')}
                        </Heading>
                        <LanguageSwitcher />
                        <Button className='mt4' onClick={closeOverlay}>
                            {t('translation.close')}
                        </Button>
                    </div>
                </Overlay>
            )}
        </header>
    );
};

export default Header;
