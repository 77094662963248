import React from 'react';
import { useTranslation } from 'react-i18next';
import './EventSection.css';

const EventSection = ({ header, items }) => {
    const { t } = useTranslation();

    return (
        <section className='p8'>
            <h2 className='text-xl text-upper text-bold mb6'>{header}</h2>
            <div className='event-section-content'>
                {items.map((item, index) => (
                    <div className='event-section-item' key={index}>
                        <img src={item.image} alt={t(item.title)} className='event-section-item-image' />
                        <div className='event-section-item-content'>
                            <p className='text-upper text-bold'>{t(item.title)}</p>
                            <p className='duration text-upper text-bold'>{t(item.duration)}</p>
                            <p className='text-left'>{t(item.description)}</p>
                            <a href={item.link} className='event-section-item-link text-upper'>
                                {t(item.linkText)}
                            </a>
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
};

export default EventSection;
