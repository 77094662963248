import React from 'react';
import Images from '../../images';
import EventSection from '../eventSection/EventSection';
import { useTranslation } from 'react-i18next';
import './Visit.css';

const experiencesAndToursItems = [
    {
        image: Images.holdingPlant,
        title: 'tours.tour_1.name',
        duration: 'tours.tour_1.time',
        description: 'tours.tour_1.text',
        link: 'https://fareharbor.com/embeds/book/starbuckshaciendaalsacia/items/73813/calendar/2024/08/?flow=no&full-items=yes&back=https://www.starbuckscoffeefarm.com/en/',
        linkText: 'tours.tour_1.button',
    },
    {
        image: Images.personalizedExperience,
        title: 'tours.tour_2.name',
        duration: 'tours.tour_2.time',
        description: 'tours.tour_2.text',
        link: 'https://docs.google.com/forms/d/e/1FAIpQLSddXcN3ifGiMPR7kUma4_DfIc-3sz3nkwYKVZpz-lGJjWpE6A/viewform?fbzx=-7384725264893224310',
        linkText: 'tours.tour_2.button',
    },
    {
        image: Images.coffeeLab,
        title: 'tours.tour_3.name',
        duration: 'tours.tour_3.time',
        description: 'tours.tour_3.text',
        link: 'https://fareharbor.com/embeds/book/starbuckshaciendaalsacia/items/534339/calendar/2024/09/?full-items=yes&back=https://www.starbuckscoffeefarm.com/en/',
        linkText: 'tours.tour_3.button',
    },
];

const eventsItems = [
    {
        image: Images.libraryPano,
        title: 'events.event_1.name',
        duration: 'events.event_1.time',
        description: 'events.event_1.text',
        link: 'https://docs.google.com/forms/d/e/1FAIpQLSddXcN3ifGiMPR7kUma4_DfIc-3sz3nkwYKVZpz-lGJjWpE6A/viewform?fbzx=-7384725264893224310',
        linkText: 'events.event_1.button',
    },
    {
        image: Images.greenhouse,
        title: 'events.event_2.name',
        duration: 'events.event_2.time',
        description: 'events.event_2.text',
        link: 'https://docs.google.com/forms/d/e/1FAIpQLSddXcN3ifGiMPR7kUma4_DfIc-3sz3nkwYKVZpz-lGJjWpE6A/viewform?fbzx=-7384725264893224310',
        linkText: 'events.event_2.button',
    },
    {
        image: Images.wedding,
        title: 'events.event_3.name',
        duration: 'events.event_3.time',
        description: 'events.event_3.text',
        link: 'https://docs.google.com/forms/d/e/1FAIpQLSddXcN3ifGiMPR7kUma4_DfIc-3sz3nkwYKVZpz-lGJjWpE6A/viewform?fbzx=-7384725264893224310',
        linkText: 'events.event_3.button',
    },
];

const Visit = () => {
    const { t } = useTranslation();

    return (
        <div>
            <EventSection header={t('tours.header')} items={experiencesAndToursItems} />
            <EventSection header={t('events.header')} items={eventsItems} />
            <section className='visit-us-section'>
                <h2 className='text-xl text-upper text-bold mb6'>{t('visitUs.header')}</h2>
                <div className='visit-us-image-container px8'>
                    <img src={Images.overviewFromTop} alt='Visit Us' className='visit-us-image' />
                </div>
                <div className='visit-us-info px8'>
                    <div className='contact-info'>
                        <p>{t('visitUs.phone')}</p>
                    </div>
                    <div className='address-info'>
                        <p>{t('visitUs.address')}</p>
                    </div>
                    <div className='hours-info'>
                        <p>{t('visitUs.week')}</p>
                        <p>
                            {t('visitUs.visitor')}: {t('visitUs.visitorTime')}
                        </p>
                        <p>
                            {t('visitUs.tour')}: {t('visitUs.tourTime')}
                        </p>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Visit;
