import React from 'react';
import './Coffee.css';
import { useTranslation } from 'react-i18next';

const CoffeeCard = ({ imageSrc, title, description, link, linkText }) => {
    const { t } = useTranslation();

    return (
        <div role='listitem' className='feed_item w-dyn-item'>
            <div className='margin-bottom margin-xsmall'>
                <div className='feed_image-wrapper'>
                    <img alt={title} loading='lazy' src={imageSrc} className='feed_image' />
                </div>
            </div>
            <div className='margin-bottom margin-xxsmall'>
                <span className='feed_item-title is-coffee'>{t('traceability.ha')}</span> <strong>{t(title)}</strong>
            </div>
            <div className='margin-top margin-xxsmall'>
                <a aria-labelledby={title} href={link} className='link underline-trail'>
                    {t(linkText)}
                </a>
            </div>
        </div>
    );
};

export default CoffeeCard;
