import React, { useEffect } from 'react';

function ElfSightSection() {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://static.elfsight.com/platform/platform.js';
        script.defer = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <section className='ha-instagram-section'>
            <div className='px9 my9'>
                <div className='elfsight-app-9b0afe2a-e218-4214-9d34-0e8919e777b1' data-elfsight-app-lazy></div>
            </div>
        </section>
    );
}

export default ElfSightSection;
