import React from 'react';
import './HeroSection.css';
import Images from '../../images';

const HeroSection = () => {
    return (
        <div className='hero-section-container'>
            <div className='hero-section' style={{ backgroundImage: `url(${Images.haLandscapePano})` }}>
                <img src={Images.van} alt='Description 1' className='floating-image image1' />
                <img src={Images.roofView} alt='Description 2' className='floating-image image2' />
                <img src={Images.seeding} alt='Description 3' className='floating-image image3' />
                <img src={Images.zoomInPullingCoffee} alt='Description 4' className='floating-image image4' />
                <img src={Images.coffeeCherry} alt='Description 5' className='floating-image image5' />
            </div>
        </div>
    );
};

export default HeroSection;
