import HeroSection from '../heroSection/HeroSection';
import WelcomeSection from '../welcomeSection/WelcomeSection';
import BookingExperiencesSection from '../bookingExperienceSection/BookingExperiencesSection';
import CoffeeSection from '../coffeeSection/CoffeeSection';
import RelatedStories from '../storySection/RelatedStories';
import Images from '../../images';
import ElfSightSection from '../elfSightSection/ElfSightSection';

const coffees = [
    {
        imageSrc: Images.banner_blonde,
        title: 'traceability.coffee.blonde',
        link: 'https://traceabilityreactappcert.z13.web.core.windows.net/#/hacienda-alsacia/blonde-roast',
        linkText: 'traceability.readMore',
    },
    {
        imageSrc: Images.banner_medium,
        title: 'traceability.coffee.medium',
        link: 'https://traceabilityreactappcert.z13.web.core.windows.net/#/hacienda-alsacia/medium-roast',
        linkText: 'traceability.readMore',
    },
    {
        imageSrc: Images.banner_dark,
        title: 'traceability.coffee.dark',
        link: 'https://traceabilityreactappcert.z13.web.core.windows.net/#/hacienda-alsacia/dark-roast',
        linkText: 'traceability.readMore',
    },
    {
        imageSrc: Images.banner_limited,
        title: 'traceability.coffee.limited',
        link: 'https://traceabilityreactappcert.z13.web.core.windows.net/#/hacienda-alsacia/limited-release',
        linkText: 'traceability.readMore',
    },
];

const stories = [
    {
        image: Images.story_10YearsImpact,
        title: 'story.story_1.name',
        date: 'story.story_1.date',
        link: 'https://stories.starbucks.com/stories/2023/ten-years-of-impact-at-hacienda-alsacia/',
    },
    {
        image: Images.story_ImpactReport,
        title: 'story.story_2.name',
        date: 'story.story_2.date',
        link: 'https://stories.starbucks.com/uploads/2024/02/2023-Starbucks-Global-Impact-Report.pdf',
    },
    {
        image: Images.story_6000GreenerStores,
        title: 'story.story_3.name',
        date: 'story.story_3.date',
        link: 'https://stories.starbucks.com/press/2024/starbucks-announces-6000-greener-stores-worldwide/',
    },
];

function Home() {
    return (
        <div className='home-page mb9'>
            <HeroSection />
            <WelcomeSection />
            <BookingExperiencesSection />
            <CoffeeSection coffees={coffees} />
            <ElfSightSection />
            <RelatedStories stories={stories} />
        </div>
    );
}

export default Home;
