const images = {
    // HA TM
    haTM: require('./TM.png'),

    // Top image section

    coffeeCherry: require('./coffeeCherry.jpg'),
    coffeeLab: require('./coffeeLab.jpg'),
    coffeeTasting: require('./coffeeTasting.jpg'),
    farmOverviewVisitors: require('./farmOverviewVisitors.png'),
    gate: require('./gate.jpg'),
    greenhouse: require('./greenhouse.jpg'),
    holdingPlant: require('./holdingPlant.jpg'),
    haLandscapePano: require('./landscapePano.jpg'),
    libraryPano: require('./libraryPano.jpg'),
    overviewFromTop: require('./overviewFromTop.jpg'),
    pullingCoffee: require('./pullingCoffee.jpg'),
    personalizedExperience: require('./personalizedExperience.jpg'),
    roasting: require('./roasting.jpg'),
    roofView: require('./roofView.jpg'),
    seeding: require('./seeding.jpg'),
    sirenLogo: require('./coffeeCherry.jpg'),
    story_10YearsImpact: require('./story_10YearsImpact.png'),
    story_6000GreenerStores: require('./story_6000GreenerStores.png'),
    story_ImpactReport: require('./story_ImpactReport.png'),
    van: require('./van.jpg'),
    wedding: require('./wedding.png'),
    zoomInPullingCoffee: require('./zoomInPullingCoffee.jpg'),
    TM_top: require('./TM_top.png'),
    TM_center: require('./TM_center.png'),
    TM_white: require('./TM_white.png'),
    floral: require('./floral.png'),
    floral_top: require('./floral_top.png'),
    banner_blonde: require('./banner_blonde.jpg'),
    banner_dark: require('./banner_dark.jpg'),
    banner_limited: require('./banner_limited.jpg'),
    banner_medium: require('./banner_medium.jpg'),
    pin: require('./pin.png'),
    tripadvisor: require('./tripadvisor.png'),
};

export default images;
