import './App.css';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import { HashRouter, Route, Routes } from 'react-router-dom';
import Home from './components/pages/Home';
import Visit from './components/pages/Visit';
import './utils/i18n';

function App() {
    return (
        <div className='App'>
            <Header />
            <Routes>
                <Route index element={<Home />} />
                <Route path='visit' element={<Visit />} />
            </Routes>
            <Footer />
        </div>
    );
}

export default App;
