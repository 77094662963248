import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLanguage } from '../../context/LanguageContext';
import Select from '@cxeweb/pattern-library/lib/components/select';

const LanguageSwitcher = () => {
    const { t } = useTranslation();
    const { language, changeLanguage } = useLanguage();

    const handleLanguageChange = (event) => {
        console.log('handleLanguageChange: ', event.target.value);
        changeLanguage(event.target.value);
    };

    return (
        <Select value={language} onChange={handleLanguageChange} aria-label='Select Language'>
            <option value='en'> {t('translation.english')}</option>
            <option value='es'> {t('translation.spanish')}</option>
        </Select>
    );
};

export default LanguageSwitcher;
