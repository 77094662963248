import React from 'react';
import { useTranslation } from 'react-i18next';
import CoffeeCard from './CoffeeCard';
import './Coffee.css';

const CoffeeSection = ({ coffees }) => {
    const { t } = useTranslation();

    return (
        <section className='section_featured-feed'>
            <div className='px9'>
                <div className='container-large-1'>
                    <div className='padding-section-medium'>
                        <div className='featured-feed_component'>
                            <div className='vertical-heading-container'>
                                <div className='text-style-vertical'>{t('traceability.header')}</div>
                            </div>
                            <div className='feed_list-wrapper w-dyn-list'>
                                <div role='list' className='feed_list w-dyn-items'>
                                    {coffees.map((coffee, index) => (
                                        <CoffeeCard
                                            key={index}
                                            imageSrc={coffee.imageSrc}
                                            title={coffee.title}
                                            description={coffee.description}
                                            link={coffee.link}
                                            linkText={coffee.linkText}
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>
                        {/* <div className='right-button_container'>
                            <a
                                aria-label='Explore all Coffees'
                                href='/our-coffee/coffee-archive'
                                className='button is-link is-icon w-inline-block'
                            >
                                <div>Explore</div>
                                <div className='icon-embed-xsmall w-embed'>
                                    <svg
                                        width='100%'
                                        height='100%'
                                        viewBox='0 0 18 8'
                                        fill='none'
                                        xmlns='http://www.w3.org/2000/svg'
                                    >
                                        <path d='M0 5V3H15V5H0ZM18 4L14 8V0L18 4Z' fill='CurrentColor'></path>
                                    </svg>
                                </div>
                            </a>
                        </div> */}
                    </div>
                    {/* <div className='border-modifier'></div> */}
                </div>
            </div>
        </section>
    );
};

export default CoffeeSection;
